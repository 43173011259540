import { tracking } from '@iwoca/frontend-tracking-library';

export function setUpAmplitude(isDE: boolean) {
  function isProductionHostname() {
    return /(business|www)\.iwoca\.(co\.uk|de)/i.test(window.location.hostname);
  }

  if (process.env.NODE_ENV !== 'test') {
    const AMPLITUDE_API_KEY = isProductionHostname()
      ? 'c8deac245de40e642fe0dbb2bbb0d2d2'
      : '064ff9e1580c5525bd401a4c516c5a17';

    tracking.initialise({
      environment: isProductionHostname() ? 'production' : 'development',
      clientConfiguration: {
        // @ts-expect-error
        apiKey: AMPLITUDE_API_KEY,
        defaultTracking: {
          pageViews: {
            trackHistoryChanges: 'all',
          },
          sessions: true,
        },
      },
    });
  }

  tracking.ampli.identify(undefined, {
    Project: 'Direct signup',
    site: isDE ? 'DE' : 'UK',
  });
}
